
@media (max-width: 768px) {
  .test-footer {
    display: block!important;
    &__return {
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }
    &__buttons {
      justify-content: space-between;
      padding: 0 15px;
    }
  }
}
